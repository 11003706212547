import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Works from "./pages/works/Works";
import Header from "./header-component/header-component";
import Footer from "./footer-component/footer-component";
import Project from "./pages/project/Project";
// import ProjectList from "./pages/project-list/ProjectList";
import appConfig from "./app-config/projects.json";
import Main from "./pages/main/Main";
// import Empty from "./pages/redi/Empty";

function App() {
  // const groupedProjects = Object.groupBy(
  //   appConfig.projects,
  //   ({ category }) => category
  // );
  // const categories = Object.keys(groupedProjects);

  const groupBy = (input, key) => {
    return input.reduce((acc, currentValue) => {
      let groupKey = currentValue[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});
  };

  const mainProjects = appConfig.projects.filter((z) => z.main === true);

  const groupedProjects = groupBy(appConfig.projects, "category");

  const categories = Object.keys(groupedProjects);

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          {/* <Route path="/redirect" element={<Empty />}></Route> */}
          <Route path="/" element={<Main projects={mainProjects} />}></Route>
          <Route key="about" path="/sobre" element={<About />}></Route>
          <Route
            path="/projetos"
            element={<Works projects={appConfig.projects} />}
          ></Route>
          {appConfig.projects.map((z) => (
            <Route
              path={"/" + z.title + z.caption}
              key={z.title + z.caption}
              element={
                <Project key={z.title + "page" + z.caption} project={z} />
              }
            />
          ))}
          {categories.map((z) => (
            <Route
              path={"/" + z}
              key={z + "main"}
              element={<Main key={"main" + z} projects={groupedProjects[z]} />}
            />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
