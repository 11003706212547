import React from "react";
import "./Works.css";
import { useNavigate } from "react-router-dom";

const Works = ({ projects }) => {
  const navigate = useNavigate();
  const navigateToPage = (navigateTo) => {
    navigate(navigateTo);
  };

  // const groupedProjects = Object.groupBy(projects, ({ category }) => category);

  const groupBy = (input, key) => {
    return input.reduce((acc, currentValue) => {
      let groupKey = currentValue[key];
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});
  };

  const groupedProjects = groupBy(projects, "category");

  const categories = Object.keys(groupedProjects);

  return (
    <div className="works">
      {categories.map((z) => (
        <div className="category" key={"category" + z}>
          <p
            className="category-name"
            key={z}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "instant" });
              navigateToPage(`${"/" + z}`);
            }}
          >
            {z}
          </p>
          <div className="category-projects">
            {groupedProjects[z].map((z) => (
              <img
                className="project-thumb"
                key={z.title + z.caption + "sm-thumb"}
                src={z.videoThumbnailSmall}
                alt={z.title}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "instant" });
                  navigateToPage(`${"/" + z.title + z.caption}`);
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Works;
